import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TealiumService } from '@services/tealium/tealium.service';
import { ModalService } from '@shared/components/modal/modal.service';
import { SiloApiService } from '@services/siloApi.service';
import { GatewayService } from '@services/gateway.service';
import { distinctUntilChanged, finalize } from 'rxjs/operators';
import { version } from '../../../../../package.json';
import { BehaviorSubject, Subscription } from 'rxjs';
import { base64regex } from '@shared/helpers/regexp.helpers';
import { ApplicationCodeEnum } from '@shared/enums/application-code.enum';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-bimpli-login-component',
  templateUrl: './bimpli-login.component.html',
  styleUrls: ['./bimpli-login.component.scss', '../../../../assets/css/bimpli-global.scss']
})
export class BimpliLoginComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() messageErreur;
  @Input() isMobile: boolean;
  @Input() isSubmitted: boolean;
  @Input() isLoading: boolean;
  @Input() saml: string;
  @Input() libelleType: string;
  @Input() askOTP: boolean;
  @Input() otpType: string;
  @Input() maskedEmail: string;
  @Input() maskedPhone: string;

  @Output() triggerRegister: EventEmitter<any> = new EventEmitter();
  @Output() triggerForgotLogin: EventEmitter<any> = new EventEmitter();
  @Output() triggerForgotPassword: EventEmitter<any> = new EventEmitter();
  @Output() triggerSubmit: EventEmitter<any> = new EventEmitter();

  isPasswordDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public login: FormControl;
  public password: FormControl;
  public otp: FormControl;
  public ginsc: FormGroup;
  public version: string = version;
  public messageInfo: string;
  public rememberLogin: boolean;
  public checkBoxRember: boolean;
  public knownLogin: string;
  public knownFirstName: string;
  public pageLoading = true;
  captchaResponse: string;

  constructor(private readonly formBuilder: FormBuilder, private router: Router,
              private readonly tealiumService: TealiumService,
              private modalService: ModalService, private readonly siloApiService: SiloApiService,
              private readonly gatewayService: GatewayService,
              private readonly reCaptchaV3Service: ReCaptchaV3Service) {}

  ngOnInit(): void {
    this.login = this.formBuilder.control(undefined, Validators.required);
    this.password = this.formBuilder.control(undefined, Validators.required);
    this.otp = this.formBuilder.control(undefined);
    this.ginsc = this.formBuilder.group([this.login, this.password, this.otp]);
    this.knownLogin = localStorage.getItem('login');
    if (localStorage.getItem('accountAlreadyExist')) {
      this.messageInfo = localStorage.getItem('accountAlreadyExist');
    }
    if (this.knownLogin && base64regex.test(this.knownLogin)) {

      this.reCaptchaV3Service
        .execute('LoginRememberMeAccountAction')
        .pipe(finalize(() => {
          this.knownLogin = window.atob(this.knownLogin);
          this.gatewayService.getAccessToken().subscribe((authToken) => {
            this.siloApiService
              .infosUser(undefined, undefined, this.knownLogin, undefined, authToken, this.captchaResponse)
              .pipe(finalize(()=> this.pageLoading = false))
              .subscribe(
                (result) => {
                  if (result.items && result.items.length > 0 && result.items[0].person) {
                    this.knownFirstName = result.items[0].person.firstName;
                    this.rememberLogin = true;
                    this.checkBoxRember = true;
                    this.login.setValue(this.knownLogin);
                    if (this.knownLogin.includes('@')) {
                      const indexAt = this.knownLogin.indexOf('@');
                      let begin = this.knownLogin.substring(0, indexAt);
                      if (begin.length >= 5) {
                        begin = begin.substr(0,3) + '*'.repeat(begin.length - 3);
                      } else {
                        begin = begin.substr(0,1) + '*'.repeat(begin.length - 1);
                      }
                      const indexDomain = this.knownLogin.lastIndexOf('.');
                      let middle = this.knownLogin.substring(indexAt, indexDomain);
                      if (middle.length >= 3) {
                        middle = middle.substr(0,2) + '*'.repeat(middle.length - 3) + middle.substr(middle.length - 1);
                      }
                      this.knownLogin = begin + middle + this.knownLogin.substr(indexDomain);
                    } else {
                      if (this.knownLogin.length >= 5) {
                        this.knownLogin = this.knownLogin.substr(0,3) + '*'.repeat(this.knownLogin.length - 3);
                      } else {
                        this.knownLogin = this.knownLogin.substr(0,1) + '*'.repeat(this.knownLogin.length - 1);
                      }
                    }
                  }
                },
                (error) => {

                },
              );
          });

        }))
        .subscribe((token) => (this.captchaResponse = token));
    } else {
      this.pageLoading = false;
    }
  }

  ngAfterViewInit() {
    if (this.messageInfo) {
      this.modalService.open('infoModal');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isLoading) {
      if (!this.isLoading && this.messageErreur) {
        this.modalService.open('errorModal');
      }
    }
  }

  connect(f: NgForm): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    if (!this.checkBoxRember) {
      localStorage.removeItem('login');
    }
    if (this.ginsc.valid) {
      this.triggerSubmit.emit({ login: this.login.value, password: this.password.value, remember: this.checkBoxRember, otpCode: this.otp?.value });
      this.otp?.setValue('');
    }
  }

  saisirAutreLogin() {
    if (this.rememberLogin) {
      this.login.setValue(undefined);
      this.password.setValue(undefined);
      this.ginsc.reset();
      this.isSubmitted = false;
      this.rememberLogin = false;
    }
  }

  register() {
    localStorage.setItem('type', 'creation');
    this.triggerRegister.emit();
  }

  lier() {
    localStorage.setItem('type', 'synchronisation');
    this.router.navigateByUrl(`${RoutesEnum.CREATE_ACCOUNT_FIRST_STEP}/${this.saml}/cado`);
  }

  forgotLogin() {
    this.triggerForgotLogin.emit();
  }

  forgotPassword() {
    this.triggerForgotPassword.emit();
  }

  getMentionsLegales() {
    this.tealiumService.view('apz.web.login.mentionLegalLoad');
    this.router.navigateByUrl('/notice/' + this.saml);
  }

  getRGPD() {
    this.tealiumService.view('apz.web.login.rgpdLoad');
    this.router.navigateByUrl('/rgpd/' + this.saml);
  }

  getCGU() {
    this.tealiumService.view('apz.web.login.cguLoad');
    this.router.navigateByUrl('/cgu/' + this.saml);
  }

  back(): void {
    window.history.back();
  }

  closePopin(id: string) {
    localStorage.removeItem('accountAlreadyExist');
    this.messageErreur = null;
    this.modalService.close(id);
  }

  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, '').slice(0, 6);
    this.otp.get('otp')?.setValue(input.value, { emitEvent: false });
  }
}
