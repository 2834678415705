import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators, ɵInternalFormsSharedModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, filter, finalize, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SiloApiService } from 'src/app/shared/services/siloApi.service';
import { GatewayService } from 'src/app/shared/services/gateway.service';
import { GroundTruthDataService } from 'src/app/shared/services/ground-truth-data.service';
import { Erreurs, CodeErreurs } from 'src/app/shared/models/errors.model';
import { ApplicationCodeEnum } from 'src/app/shared/enums/application-code.enum';
import { ProductEnum } from 'src/app/shared/enums/product.enum';
import { SessionQuery } from 'src/app/shared/services/store/session/session.query';
import { SessionStateService } from 'src/app/shared/services/store/session/session-state.service';
import { RoutesEnum } from 'src/app/shared/enums/routes.enum';
import { TealiumService } from '../../shared/services/tealium/tealium.service';
import { environment } from '../../../environments/environment';
import { cellPhoneRegex } from 'nit-angular-lib';
import { AuthToken } from '@shared/models/authtoken.model';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public login: FormControl;
  public password: FormControl;
  public ginsc: FormGroup;
  public pageHtml: string;
  public messageErreur: string;
  @Input() isAlreadyActived: boolean;

  public applicationCode$: Observable<ApplicationCodeEnum>;
  public productId: ProductEnum;
  public saml: string;
  public estApetiz: boolean;
  public estMobile: boolean;
  public estBenefit: boolean;
  public applicationId: ApplicationCodeEnum;
  public ApplicationCodeEnum = ApplicationCodeEnum;
  public libelleType: string;

  private valueCaptcha: string;

  public sso: boolean;

  public isLoading: boolean;
  public isSubmitted: boolean;
  public isRemember: boolean;
  public askOTP: boolean;
  public otpType: string;
  public maskedEmail: string;
  public maskedPhone: string;
  private sentLogin: string;
  private isSecondTry: boolean;
  private isLocal: boolean;

  public constructor(
    private readonly formBuilder: FormBuilder,
    private readonly siloApiService: SiloApiService,
    private readonly translateService: TranslateService,
    private readonly gatewayService: GatewayService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly gtds: GroundTruthDataService,
    private readonly sessionQuery: SessionQuery,
    private readonly sessionStateService: SessionStateService,
    private readonly tealiumService: TealiumService,
    private readonly reCaptchaV3Service: ReCaptchaV3Service
  ) {}

  public ngOnInit(): void {
    this.sso = true;
    this.login = this.formBuilder.control(undefined, Validators.required);
    this.password = this.formBuilder.control('', Validators.required);
    this.ginsc = this.formBuilder.group([this.login, this.password]);

    this.sessionStateService.setApplicationCode(this.route);

    this.applicationCode$ = this.sessionQuery.selectedApplicationCode;

    this.sessionQuery.isLocal.subscribe((isLocal) => {
      this.isLocal = isLocal;
    });

    this.applicationCode$.subscribe((code) => {
      this.applicationId = code;
      switch (code) {
        case ApplicationCodeEnum.APZ:
          this.estApetiz = true;
          this.tealiumService.view('apz.web.login.pageLoad');
          break;
        case ApplicationCodeEnum.APZ_MOBILE:
          this.estMobile = true;
          this.tealiumService.view('apz.mobile.login.pageLoad');
          break;
        case ApplicationCodeEnum.BENEFIT_BENEF:
          this.libelleType = 'COLLABORATEUR';
          this.tealiumService.view('bimpli_benef.web.login.pageLoad');
          this.estBenefit = true;
          break;
        case ApplicationCodeEnum.BENEFIT_CAGNOTTE:
          this.estBenefit = true;
          break;
        case ApplicationCodeEnum.BENEFIT_FINANCEUR:
          this.libelleType = 'ENTREPRISE';
          this.tealiumService.view('bimpli_financeur.web.login.pageLoad');
          this.estBenefit = true;
          break;
        case ApplicationCodeEnum.BENEFIT_MOBILE:
          this.tealiumService.view('bimpli_mobile.web.login.pageLoad');
          this.estBenefit = true;
          this.estMobile = true;
          break;
      }
    });

    this.sessionQuery.selectedProductId
      .pipe(
        distinctUntilChanged(),
        filter((productId) => productId && true && productId !== this.productId),
        tap((productId) => (this.productId = productId))
      )
      .subscribe();

    this.sessionQuery.selectedSaml
      .pipe(
        distinctUntilChanged(),
        filter((saml) => saml && true && saml !== this.saml),
        tap((saml) => (this.saml = saml))
      )
      .subscribe();

    this.messageErreur = null;

    // Pour l'application mobile apétiz on ne fait pas de SSO
    if (ApplicationCodeEnum.APZ_MOBILE !== this.applicationId && ApplicationCodeEnum.BENEFIT_MOBILE !== this.applicationId) {
      this.checkCookieSso();
    } else {
      this.sso = false;
    }
  }

  public validerInscription(f: NgForm): void {
    this.messageErreur = null;
    this.isSubmitted = true;
    if (this.ginsc.valid) {
      this.submit(this.login.value, this.password.value);
    }
  }

  public submit(login, password, remember?, otpCode?) {
    this.sentLogin = login;
    this.isLoading = true;
    this.isRemember = remember;
    if (this.estApetiz) {
      this.tealiumService.link('apz.web.login.loginClick');
    } else if (this.estMobile) {
      this.tealiumService.link('apz.mobile.login.loginClick');
    }
    if (ApplicationCodeEnum.BENEFIT_BENEF === this.applicationId) {
      this.tealiumService.view('bimpli_benef.web.login.loginClick');
    } else if (ApplicationCodeEnum.BENEFIT_FINANCEUR === this.applicationId) {
      this.tealiumService.view('bimpli_financeur.web.login.loginClick');
    } else if (ApplicationCodeEnum.BENEFIT_MOBILE === this.applicationId) {
      this.tealiumService.view('bimpli_mobile.web.login.loginClick');
    }
    if (this.estMobile) {
      this.isRemember = true;
      this.doOnlyConnection(login, password, otpCode);
    } else {
      this.createRequestAndDoConnection(login, password, otpCode);
    }
  }

  private checkCookieSso(): void {
    this.gatewayService.getAccessToken().subscribe((authToken) => {
      this.siloApiService.checkCookieSession(authToken).subscribe(
        (login) => {
          if (login === null) {
            this.sso = false;
            console.log('no session active for current user');
          } else {
            this.sessionQuery.selectedSaml.pipe(
              distinctUntilChanged(),
              filter((saml) => saml && true && saml !== this.saml),
              tap((saml) => (this.saml = saml))
            );
            this.createRequestAndDoConnection(login, null);
          }
        },
        (error) => {
          this.sso = false;
          console.log('error=>', error);
        }
      );
    });
  }

  private doOnlyConnection(login: string, password: string, otpCode: string): void {
    this.gatewayService.getAccessToken().subscribe(
      (authToken) => {
        this.reCaptchaV3Service
          .execute('ConnecterAction')
          .pipe(
            finalize(() => {
              this.siloApiService
                .connecterWithCaptcha(login, password, this.saml, authToken, this.valueCaptcha, otpCode)
                .pipe(
                  finalize(() => {
                    this.gtds.setIsSpinnerActivated(false);
                    this.isLoading = false;
                    this.sentLogin = login;
                  })
                )
                .subscribe(
                  (html) => {
                    if (localStorage.getItem('accountAlreadyExist')) {
                      localStorage.removeItem('accountAlreadyExist');
                    }
                    if (localStorage.getItem('login')) {
                      localStorage.removeItem('login');
                    }
                    if (this.isRemember) {
                      localStorage.setItem('login', window.btoa(this.sentLogin));
                    }

                    this.checkOTPInstruction(html);
                  },
                  (error) => this.gererErreurs(error)
                );
            })
          )
          .subscribe((token) => (this.valueCaptcha = token));
      },
      (err) => {
        this.isLoading = false;
        this.messageErreur = 'Service indisponible veuillez réassayer ultérieurement';
      }
    );
  }

  private createRequestAndDoConnection(login: string, password: string, otpCode?: string): void {
    const clientId = this.getClientId();
    const redirectUrl = this.getUrlRedirect();
    if (clientId && clientId !== '') {
      this.gatewayService.authentificate(clientId, 'token', redirectUrl).subscribe((contenuHtml) => {
        const abc = document.createElement('formSAML');
        abc.innerHTML = contenuHtml;
        const form = abc.getElementsByTagName('form')[0] as HTMLFormElement;
        this.gatewayService.getAccessToken().subscribe(
          (authToken) => {
            this.reCaptchaV3Service
              .execute('extractSAMLRequest')
              .pipe(
                finalize(() => {
                  this.siloApiService
                    // tslint:disable-next-line: no-string-literal
                    .extractSAMLRequest(
                      authToken,
                      form.elements['SAMLRequest'].value,
                      form.elements['appNameEncoded'].value,
                      this.valueCaptcha
                    )
                    .subscribe(
                      (retour) => {
                        this.saml = retour.id;

                        this.gestionConnection(login, password, authToken, otpCode);
                      },
                      (err) => {
                        this.isLoading = false;
                        this.messageErreur = 'Service indisponible veuillez réassayer ultérieurement';
                      }
                    );
                })
              )
              .subscribe((token) => (this.valueCaptcha = token));
          },
          (err) => {
            this.isLoading = false;
            this.messageErreur = 'Service indisponible veuillez réassayer ultérieurement';
          }
        );
      });
    } else {
      this.gatewayService.getAccessToken().subscribe(
        (authToken) => {
          this.gestionConnection(login, password, authToken, otpCode);
        },
        (err) => {
          this.isLoading = false;
          this.messageErreur = 'Service indisponible veuillez réassayer ultérieurement';
        }
      );
    }
  }

  public gestionConnection(login: string, password: string, authToken: AuthToken, otpCode: string): void {
    if (password === null) {
      this.siloApiService
        .connecterSSO(this.saml, authToken)
        .pipe(
          finalize(() => {
            this.gtds.setIsSpinnerActivated(false);
            this.isLoading = false;
            this.sentLogin = login;
            this.sso = false;
          })
        )
        .subscribe(
          (html) => {
            this.setPageHtml(html);
          },
          (error) => this.gererErreurs(error)
        );
    } else {
      this.reCaptchaV3Service
        .execute('ConnecterAction')
        .pipe(
          finalize(() => {
            this.siloApiService
              .connecterWithCaptcha(login, password, this.saml, authToken, this.valueCaptcha, otpCode)
              .pipe(
                finalize(() => {
                  this.gtds.setIsSpinnerActivated(false);
                  this.isLoading = false;
                  this.sentLogin = login;
                })
              )
              .subscribe(
                (html) => {
                  if (localStorage.getItem('accountAlreadyExist')) {
                    localStorage.removeItem('accountAlreadyExist');
                  }
                  if (this.isRemember) {
                    localStorage.setItem('login', window.btoa(this.sentLogin));
                  }
                  this.checkOTPInstruction(html);
                },
                (error) => this.gererErreurs(error)
              );
          })
        )
        .subscribe((token) => (this.valueCaptcha = token));
    }
  }

  public setPageHtml(html: string): void {
    document.body.innerHTML = html;
    const form = document.getElementsByTagName('form')[0] as HTMLFormElement;
    form.submit();
  }

  public gererErreurs(error: HttpErrorResponse) {
    this.sso = false;
    let resetOtp = true;
    if (error.error instanceof ErrorEvent) {
      this.messageErreur = 'Erreur inattendue : ' + error.error.message;
    } else {
      const errors: Erreurs = error.error.startsWith('<') ? undefined : JSON.parse(error.error);

      if (errors && errors.errors !== undefined) {
        switch (errors.errors[0].code) {
          case CodeErreurs.FORBIDDEN_ACTION_040:
            this.messageErreur =
              // tslint:disable-next-line: max-line-length
              'Votre compte est bloqué suite à vos différentes tentatives de connexion. Nous vous invitons à aller sur "Mot de passe oublié" pour réinitialiser votre mot de passe et débloquer votre compte.';
            break;
          case CodeErreurs.INCORRECT_DATA_007:
            this.messageErreur = `L\'identifiant et/ou le mot de passe saisis sont incorrects. Veuillez vérifier et réessayer.`;
            break;
          case CodeErreurs.IMPOSSIBLE_ACTION_041:
            console.log(this.sentLogin);
            if (this.sentLogin.includes('@')) {
              this.messageErreur = this.translateService.instant('ERROR.PENDING_ACTIVATION');
            } else if (cellPhoneRegex.test(this.sentLogin)) {
              this.messageErreur = this.translateService.instant('ERROR.PENDING_ACTIVATION_SMS');
            } else {
              this.messageErreur = this.translateService.instant('ERROR.PENDING_ACTIVATION');
            }
            break;
          case CodeErreurs.FORBIDDEN_ACTION_042:
            // tslint:disable-next-line: max-line-length
            this.messageErreur = `Votre tentative de de connexion a échoué car votre compte ${this.productId}® a été désactivé par votre employeur.\n Nous vous invitons à vous rapprocher de lui pour toute question.`;
            break;
          case CodeErreurs.IMPOSSIBLE_ACTION_013:
            // tslint:disable-next-line: max-line-length
            if (this.applicationId === ApplicationCodeEnum.BENEFIT_FINANCEUR) {
              this.messageErreur = `L\'identifiant et/ou le mot de passe saisis sont incorrects. Veuillez vérifier et réessayer.`;
            } else if (errors.errors[0].additionalInformation?.message.includes('no contact')) {
              this.messageErreur = `Un problème de données sur votre compte empêche de poursuivre l’action. Merci de prendre contact avec notre support.`;
            } else if (errors.errors[0].additionalInformation?.message.includes('Too many OTP request')) {
              this.messageErreur = `Vous avez dépassé le nombre maximum de tentatives de connexion. Veuillez réessayer dans 15 minutes.`;
            } else {
              this.messageErreur = `Vous n’êtes pas bénéficiaire ${this.productId},
                si c'est une erreur, nous vous invitons à contacter votre employeur.`;
            }
            break;
          case CodeErreurs.FORBIDDEN_ACTION_087:
            this.messageErreur = this.translateService.instant('ASP.FORBIDDEN_ACTION_087');
            break;
          case CodeErreurs.NOT_PROCESSED_039:
            resetOtp = false;
            this.messageErreur = this.translateService.instant('AFFILIE_APZ.LOGIN.NOT_PROCESSED_039');
            break;
          case CodeErreurs.NOT_FOUND_028:
            this.messageErreur = this.translateService.instant('AFFILIE_APZ.LOGIN.NOT_FOUND_028');
            break;
          default:
            this.messageErreur = 'Erreur inattendue : ' + errors.errors[0].code;
            break;
        }
      } else {
        this.messageErreur = 'Erreur ' + error.status + ' : ' + error.statusText;
      }
    }
    this.askOTP = !resetOtp;
  }

  private getClientId(): string {
    let clientId: string;
    if (this.applicationId === ApplicationCodeEnum.APZ) {
      clientId = environment.clientIdAPZ;
    } else if (this.applicationId === ApplicationCodeEnum.WEEZEN) {
      clientId = environment.clientIdWee;
    } else if (this.applicationId === ApplicationCodeEnum.CESU) {
      clientId = environment.clientIdCesu;
    } else if (this.applicationId === ApplicationCodeEnum.AFFILIE_APZ) {
      clientId = environment.clientIdAffilieAPZ;
    } else if (this.applicationId === ApplicationCodeEnum.BENEFIT_BENEF && this.productId !== ProductEnum.BIMPLI_TEST) {
      clientId = environment.clientIdBenefWeb;
    } else if (this.applicationId === ApplicationCodeEnum.BENEFIT_FINANCEUR && this.productId !== ProductEnum.BIMPLI_TEST) {
      clientId = environment.clientIdFinanceurWeb;
    } else if (this.applicationId === ApplicationCodeEnum.BENEFIT_MOBILE) {
      clientId = environment.clientIdBimpliMobile;
    }
    /*else if (this.applicationId === ApplicationCodeEnum.BENEFIT_BENEF) {

        clientId ='70a64e63-54da-4c92-90a8-915df1075116';
        redirectUrl = 'http://localhost:4200/token';

    }*/
    return clientId;
  }

  private getUrlRedirect(): string {
    let redirectUrl: string;

    if (this.applicationId === ApplicationCodeEnum.APZ) {
      if (this.isLocal) {
        redirectUrl = 'http://localhost:4200/token';
      } else {
        redirectUrl = environment.redirectURIAPZ;
      }
    } else if (this.applicationId === ApplicationCodeEnum.WEEZEN) {
      if (this.isLocal) {
        redirectUrl = 'http://localhost:4200/token';
      } else {
        redirectUrl = environment.redirectURIWee;
      }
    } else if (this.applicationId === ApplicationCodeEnum.CESU) {
      if (this.isLocal) {
        redirectUrl = 'http://localhost:4200/token';
      } else {
        redirectUrl = environment.redirectURLCesu;
      }
    } else if (this.applicationId === ApplicationCodeEnum.AFFILIE_APZ) {
      if (this.isLocal) {
        redirectUrl = 'http://localhost:4200/token';
      } else {
        redirectUrl = environment.redirectURLAffilieAPZ;
      }
    } else if (this.applicationId === ApplicationCodeEnum.BENEFIT_FINANCEUR) {
      if (this.isLocal) {
        redirectUrl = 'http://localhost:4200/token';
      } else {
        redirectUrl = environment.redirectURLFinanceurWeb;
      }
    } else if (this.applicationId === ApplicationCodeEnum.BENEFIT_BENEF) {
      if (this.isLocal) {
        redirectUrl = 'http://localhost:4200/token';
      } else {
        redirectUrl = environment.redirectURLBenefWeb;
      }
    } else if (this.applicationId === ApplicationCodeEnum.BENEFIT_MOBILE) {
      if (this.isLocal) {
        redirectUrl = 'http://localhost:4200/token';
      } else {
        redirectUrl = environment.redirectURLBimpliMobile;
      }
    }

    return redirectUrl;
  }

  private checkOTPInstruction(html: string) {
    // {"id": "1765749-27/06/2024_16:49:51","otpType": "OTPMAIL","email": "ma*************ix@n*****s.fr","phone": null}
    let json: any;
    if (html.trim().startsWith('{')) {
      try {
        json = JSON.parse(html);
        if (json.otpType === 'OTPMAIL') {
          this.askOTP = true;
          this.otpType = 'OTPMAIL';
          this.maskedEmail = json.email.join(', ');
        } else if (json.otpType === 'OTP') {
          this.askOTP = true;
          this.otpType = 'OTPSMS';
          this.maskedPhone = json.phone;
        } else {
          this.setPageHtml(html);
        }
      } catch (error) {
        this.setPageHtml(html);
      }
    } else {
      this.setPageHtml(html);
    }
  }

  forgotPassword() {
    if (this.estApetiz) {
      this.tealiumService.link('apz.web.login.forgottenPasswordClick');
    } else if (this.estMobile) {
      this.tealiumService.link('apz.mobile.login.forgottenPasswordClick');
    }
    if (ApplicationCodeEnum.BENEFIT_BENEF === this.applicationId) {
      this.tealiumService.view('bimpli_benef.web.login.forgottenPasswordClick');
    } else if (ApplicationCodeEnum.BENEFIT_FINANCEUR === this.applicationId) {
      this.tealiumService.view('bimpli_financeur.web.login.forgottenPasswordClick');
    } else if (ApplicationCodeEnum.BENEFIT_MOBILE === this.applicationId) {
      this.tealiumService.view('bimpli_mobile.web.login.forgottenPasswordClick');
    }
    this.router.navigateByUrl(`/renouvellement-mot-de-passe/${this.saml}`);
  }

  forgotLogin() {
    if (this.estApetiz) {
      this.tealiumService.link('apz.web.login.forgottenLoginClick');
    } else if (this.estMobile) {
      this.tealiumService.link('apz.mobile.login.forgottenLoginClick');
    }
    if (ApplicationCodeEnum.BENEFIT_BENEF === this.applicationId) {
      this.tealiumService.view('bimpli_benef.web.login.forgottenLoginClick');
    } else if (ApplicationCodeEnum.BENEFIT_FINANCEUR === this.applicationId) {
      this.tealiumService.view('bimpli_financeur.web.login.forgottenLoginClick');
    } else if (ApplicationCodeEnum.BENEFIT_MOBILE === this.applicationId) {
      this.tealiumService.view('bimpli_mobile.web.login.forgottenLoginClick');
    }
    this.router.navigateByUrl('/identifiant-oublie/' + this.saml);
  }

  register() {
    if (this.estApetiz) {
      this.tealiumService.link('apz.web.login.createAccountClick');
    } else if (this.estMobile) {
      this.tealiumService.link('apz.mobile.login.createAccountClick');
    }
    if (ApplicationCodeEnum.BENEFIT_BENEF === this.applicationId) {
      this.tealiumService.view('bimpli_benef.web.login.createAccountClick');
    }

    if (
      this.applicationId === ApplicationCodeEnum.BENEFIT_MOBILE ||
      this.applicationId === ApplicationCodeEnum.BENEFIT_BENEF ||
      this.applicationId === ApplicationCodeEnum.BENEFIT_CAGNOTTE ||
      this.applicationId === ApplicationCodeEnum.BENEFIT_FINANCEUR
    ) {
      this.router.navigateByUrl(`${RoutesEnum.CREATE_ACCOUNT_CHOICE}/${this.saml}`);
    } else if (this.applicationId === ApplicationCodeEnum.AFFILIE_APZ) {
      this.router.navigateByUrl(`${RoutesEnum.SIGNUP_AFFILIE_FIRST_STEP}/${this.saml}`);
    } else {
      this.router.navigateByUrl(`${RoutesEnum.CREATE_ACCOUNT_FIRST_STEP}/${this.saml}`);
    }
  }
}
